import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    CheckboxVisibility,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    FontIcon,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ShimmeredDetailsList,
    Stack,
    Text,
    Toggle,
} from "@fluentui/react";
import { fetchConsentManagement, saveConsentRequestData } from '../services/privacy';
import { Styles as CommonStyles } from "../styles/common.styles";

export class ConsentManagement extends Component {
    static displayName = ConsentManagement.name;
    constructor() {
        super();
        this.getConsentManagement = this.getConsentManagement.bind(this);
        this.state = {
            content: {},
            dataProcessingActivities: [],
            successMessage: null,
            errorMessage: null,
            isRunning: false,
            isLoaded: false,
            subjectEmail: null,
            dataSubjectRequestId: null,
            dataState: {
                skip: 0,
                take: 20,
                sort: [],
                group: []
            }
        }
        this.saveConsentData = this.saveConsentData.bind(this);
        this.backToPrivacyCenter = this.backToPrivacyCenter.bind(this);
    }

    commandCell = (row) => {
        return (
            <Toggle
                onText="Yes"
                offText="No"
                onChange={this.handleSwitchInputChange.bind(this, row)}
                defaultChecked={row.requiredConsent}
            />
        )
    }

    headerCell = (header) => {
        return (
            <a className="k-link" onClick={header.onClick} href={() => false}>
                <div style={{ textAlign: "center" }}>{header.title}cccc</div>
                {header.children}
            </a>
        );
    }

    componentDidMount() {
        if (this.props.location.state == null || this.props.location.state.subjectEmail == null || this.props.location.state.dataSubjectRequestId == null) {
            this.backToPrivacyCenter();
            return;
        }
        var subjectEmail = this.props.location.state.subjectEmail;
        var dataSubjectRequestId = this.props.location.state.dataSubjectRequestId;
        this.setState({ subjectEmail: subjectEmail, dataSubjectRequestId: dataSubjectRequestId }, () => { this.getConsentManagement() });
    }

    dataStateChange = (e) => {
        this.setState({
            ...this.state,
            dataState: e.data
        });
    }

    handleSwitchInputChange(data, e, checked) {
        var dataProcessingActivities = this.state.dataProcessingActivities;
        var consent = dataProcessingActivities.find(t => t.id === data.id);
        if (consent) {
            consent.requiredConsent = checked;
        }
        this.setState({ dataProcessingActivities: dataProcessingActivities });
    }

    async getConsentManagement() {
        var subjectEmail = this.state.subjectEmail;
        const newState = {
            isLoaded: true
        };
        const response = await fetchConsentManagement(subjectEmail);
        if (response.success) {
            newState.dataProcessingActivities = response.data.dataProcessingActivities;
            newState.content = response.data.content.htmlContent;
        }
        this.setState({ ...newState });
    }

    backToPrivacyCenter() {
        this.props.history.replace({
            pathname: "/"
        });
    }

    async saveConsentData(e) {
        this.setState({ isRunning: true });
        e.preventDefault();

        var dataSubjectRequestId = this.state.dataSubjectRequestId;
        var subjectEmail = this.state.subjectEmail;
        var dataProcessingActivities = this.state.dataProcessingActivities;
        var model = {
            dataProcessingActivities: dataProcessingActivities,
            subjectEmail: subjectEmail,
            dataSubjectRequestId: dataSubjectRequestId
        }

        const response = await saveConsentRequestData(model);
        if (response.success) {
            if (!(response.data.isError)) {
                this.setState({
                    successMessage: response.data.message
                }, () => setTimeout(() => {
                    this.setState({ successMessage: null });
                }, 10000));
                this.props.history.replace({
                    pathname: '/',
                    state: { message: "Your consent request submitted successfully. Now we will proceed your data as per your consent." }
                });
            }
            else {
                this.setState({
                    errorMessage: response.data.responseException["exceptionMessage"]
                }, () => setTimeout(() => {
                    this.setState({ errorMessage: null });
                }, 10000));
            }
        }
        else {
            this.setState({
                errorMessage: response.data.responseException["exceptionMessage"]
            }, () => setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 10000));
        }
        this.setState({ isRunning: false });
    }


    render() {

        const { dataProcessingActivities, content, isLoaded, successMessage, errorMessage, isRunning } = this.state;
        return (
            <Stack>
                <Stack.Item>
                    <Link to="/" style={CommonStyles.iconLink}>
                        <div>
                            <FontIcon
                                iconName="Back"
                                aria-hidden={true}
                                style={CommonStyles.backIcon}
                            />
                        </div>
                        <span>Back to Privacy Center</span>
                    </Link>
                    <Text variant="xxLarge">Data Subject Request</Text>
                </Stack.Item>
                <Stack.Item>
                    <div>
                        {successMessage && (
                            <MessageBar messageBarType={MessageBarType.success}>
                                {successMessage}
                            </MessageBar>
                        )}
                        {errorMessage && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {errorMessage}
                            </MessageBar>
                        )}
                    </div>
                    <div
                        style={CommonStyles.mt2}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    ></div>
                    <div style={CommonStyles.mt2}>
                        <PrimaryButton
                            className={CommonStyles.primaryButton({
                                backgroundColor: window.primaryColor,
                                color: window.secondaryColor,
                            })}
                            text="Confirm"
                            onClick={this.saveConsentData}
                            disabled={isRunning}
                        />
                        <DefaultButton
                            text="Cancel"
                            onClick={this.backToPrivacyCenter}
                            styles={{ root: { marginLeft: "10px" } }}
                        />
                    </div>
                    <ShimmeredDetailsList
                        items={dataProcessingActivities || []}
                        columns={[
                            {
                                key: "title",
                                name: "Data Processing Activity",
                                fieldName: "title",
                                minWidth: 100,
                                maxWidth: 200,
                                isResizable: true,
                            },
                            {
                                key: "description",
                                name: "Description",
                                fieldName: "description",
                                minWidth: 100,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: "fields",
                                name: "Data Classes",
                                fieldName: "fields",
                                minWidth: 100,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: "id",
                                name: "Do You Consent?",
                                fieldName: "id",
                                minWidth: 100,
                                maxWidth: 150,
                                isResizable: true,
                                onRender: this.commandCell,
                            },
                        ]}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        shimmerLines={5}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionMode={"none"}
                        enableShimmer={!isLoaded}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </Stack.Item>
            </Stack>
        );
    }
}
