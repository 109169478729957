import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    FontIcon,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    Text,
} from "@fluentui/react";
import Loader from "./loader";
import { confirm } from "./confirm";
import { authenticationService } from "../services/authenticationservice";
import { cancelDataSubjectRequest, fetchDsrByEmail } from '../services/privacy';
import { Styles } from "../styles/datasubjectrequestlist.styles";
import { Styles as CommonStyles } from "../styles/common.styles";
import { CANCELABLE_DSR_STATUS } from "./constant";

export class DataSubjectRequestList extends Component {
    static displayName = DataSubjectRequestList.name;
    constructor() {
        super();
        this.state = {
            dataSubjectRequest: [],
            dataSubjectRequestGroups: [],
            successMessage: null,
            errorMessage: null,
            isLoaded: false,
            isRunning : false,
        }

        this.getDataSubjectRequest = this.getDataSubjectRequest.bind(this);
        this.backToPrivacyCenter = this.backToPrivacyCenter.bind(this);
        this.cancelRequest = this.cancelRequest.bind(this);
    }

    commandCell = (row) => {
        return (CANCELABLE_DSR_STATUS.includes(row.status)) ? (
            <DefaultButton 
                text="Cancel"
                onClick={this.cancelRequest.bind(this, row)}
            />
        ) : row.downloadUrl && row.downloadUrl !== "" ? (
            <PrimaryButton 
                className={CommonStyles.primaryButton({ backgroundColor: window.primaryColor, color: window.secondaryColor })}
                text="Download Data"
                onClick={this.downloadData.bind(this, row)}
            />
        ) : null;
    }

    downloadData = (row) => {
        window.location.href = row.downloadUrl
    }

    cancelRequest = (row) => {
        if (!this.state.isRunning) {
            confirm("Are you sure want to cancel this request?", { title: "Cancel ?", okLabel: "Ok", cancelLabel: "Cancel", modalProps: { isBlocking: true } }).then(async () => {
                var id = row.id;
                this.setState({ isRunning: true });
                const response = await cancelDataSubjectRequest({ id });
                if (response.success) {
                  if (!(response.data.isError)) {
                    this.setState({
                        successMessage: response.data.message
                    }, () => setTimeout(() => {
                        this.setState({ successMessage: null });
                    }, 10000));
                    this.setState({
                        isRunning: false,
                    }, () => {
                        this.getDataSubjectRequest();
                    });
                  } else {
                    this.setState({ isRunning: false });
                    this.setState({
                        errorMessage: response.data.responseException["exceptionMessage"]
                    }, () => setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 10000));
                  }
                }
                else {
                    this.setState({ isRunning: false });
                    this.setState({
                        errorMessage: response.data.responseException["exceptionMessage"]
                    }, () => setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 10000));
                }

            }, () => { });
        }
        else {
            this.setState({
                errorMessage: "Cancel request already processing."
            }, () => setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 5000));
        }
    }

    componentDidMount() {
        if (!authenticationService.isAuthenticate()) {
            this.backToPrivacyCenter();
            return;
        }

        this.getDataSubjectRequest();
    }

    groupByKey = function(data, key) {
        return data.reduce(function(result, item) {
          (result[item[key]] = result[item[key]] || []).push(item);
          return result;
        }, {});
    }

    async getDataSubjectRequest() {
        var userData = authenticationService.currentUserValue;
        const newState = {
            isLoaded: true
        };
        const response = await fetchDsrByEmail({ email: userData.email });
        if (response.success) {
            const items = response.data ? response.data.dataSubjects : [];
            const dataSubjectByRequestTypes = this.groupByKey(items, "requestType");
            const dataSubjectRequests = [];
            const dataSubjectRequestGroups = []
            // eslint-disable-next-line
            Object.entries(dataSubjectByRequestTypes).map(([key, values]) => {
                dataSubjectRequestGroups.push({
                    key: key,
                    name: key,
                    startIndex: dataSubjectRequests.length,
                    count: values.length,
                    level: 0,
                });
                dataSubjectRequests.push(...values);
            });
            newState.dataSubjectRequest = dataSubjectRequests;
            newState.dataSubjectRequestGroups = dataSubjectRequestGroups;
        }
        this.setState({ ...newState });
    }

    backToPrivacyCenter() {
        this.props.history.replace({
            pathname: "/"
        });
    }


    render() {
        const {
            dataSubjectRequest,
            dataSubjectRequestGroups,
            successMessage,
            errorMessage,
            isLoaded,
        } = this.state;

        return (
            <Stack>
                <Stack.Item>
                    <Link to="/" style={CommonStyles.iconLink}>
                        <div>
                            <FontIcon
                                iconName="Back"
                                aria-hidden={true}
                                style={CommonStyles.backIcon}
                            />
                        </div>
                        <span>Back to Privacy Center</span>
                    </Link>
                    <Text variant="xxLarge">Data Subject Request</Text>
                </Stack.Item>
                <Stack.Item>
                    <div>
                        {successMessage && (
                            <MessageBar messageBarType={MessageBarType.success}>
                                {successMessage}
                            </MessageBar>
                        )}
                        {errorMessage && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {errorMessage}
                            </MessageBar>
                        )}
                    </div>
                    {isLoaded ? (
                        <React.Fragment>
                            <DetailsList
                                layoutMode={DetailsListLayoutMode.justified}
                                compact={false}
                                selectionMode={"none"}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                groupProps={{
                                    headerProps: {
                                        selectionMode: "none",
                                    },
                                    showEmptyGroups: true,
                                }}
                                items={dataSubjectRequest}
                                groups={dataSubjectRequestGroups}
                                className={Styles.detailsListClass({
                                    color: window.secondaryColor,
                                })}
                                columns={[
                                    {
                                        key: "requestType",
                                        name: "Request Type",
                                        fieldName: "requestType",
                                        isResizable: true,
                                    },
                                    {
                                        key: "requestDetails",
                                        name: "Request Details",
                                        fieldName: "requestDetails",
                                        isResizable: true,
                                    },
                                    {
                                        key: "status",
                                        name: "Status",
                                        fieldName: "status",
                                        isResizable: true,
                                    },
                                    {
                                        key: "date",
                                        name: "Created Date",
                                        fieldName: "createdDate",
                                        minWidth: 180,
                                        isResizable: true,
                                        onRender: (item) => new Date(item.createdDate).toLocaleString(),
                                    },
                                    {
                                        key: "id",
                                        name: "Action",
                                        isResizable: true,
                                        onRender: (item) => this.commandCell(item),
                                    },
                                ]}
                            />
                        </React.Fragment>
                    ) : (
                        <Loader />
                    )}
                </Stack.Item>
            </Stack>
        );
    }
}
