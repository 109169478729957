import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Stack } from "@fluentui/react";
import { authenticationService } from "../services/authenticationservice";
import { REQUESTTYPES } from "./constant";
import { fetchDsrByEmail } from '../services/privacy';
import { Styles } from '../styles/headers.styles';

export class Header extends Component {
    static displayName = Header.name;

    constructor(props) {
        super(props);
        this.state={
            headerTextColor: null,
            headerColor:null
        }
    }
    componentDidMount() {
         this.lightOrDark(window.headerColor)
    }

    logout = () => {
        authenticationService.logout();
        window.location.href = "/";
    };
    lightOrDark(color) {
        let r, g, b, hsp;
        if (color === null) {
            this.setState({
                headerTextColor: '#000000',
                headerColor: "#ffffff"
            })
        } else {
            if (color && color.match(/^rgb/)) {
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
                r = color[1];
                g = color[2];
                b = color[3];
            } else {
                color = +("0x" + color.slice(1).replace(
                        color.length < 5 && /./g, '$&$&'
                    )
                );
                r = color >> 16;
                g = color >> 8 && 255;
                b = color & 255;
            }
            // HSP (Highly Sensitive Poo)
            hsp = Math.sqrt(
                0.299 * (r * r) +
                0.587 * (g * g) +
                0.114 * (b * b)
            );
            this.setState({
                headerColor: color[4] === "0" ? "#ffffff" : window.headerColor
            })
            if (hsp >= 127.5) {
                this.setState({
                    headerTextColor: '#000000'
                })
            } else {
                this.setState({
                    headerTextColor: '#ffffff'
                })
            }
        }
    }
    render() {
        return (
            <Stack
                horizontal
                grow
                wrap
                className={ `main-header `}
                styles={Styles.privacyHeader({color: this.state.headerColor})}
            >
                <Stack.Item style={Styles.mainLogo}>
                    <Link to={{ pathname: "/" }} style={{ display: "flex" }}>
                        <img alt="mainLogo" className={Styles.headerLogo()} style={Styles.mainLogo} src={window.logoPath} />
                    </Link>
                </Stack.Item>
                <Stack.Item style={Styles.headerText}>
                    <div className={Styles.headerTextInner({color: this.state.headerTextColor})}>
                        Privacy Center
                    </div>
                </Stack.Item>
                <Stack.Item style={Styles.LoggedInUser}>
                    <LoggedInUser logout={this.logout} />
                </Stack.Item>
            </Stack>
        );
    }
}

function LoggedInUser(props) {
    const [isAuthenticate, setAuthenticate] = useState(
        authenticationService.isAuthenticate()
    );
    const userData = authenticationService.currentUserValue;

    useEffect(() => {
        setInterval(() => {
            setAuthenticate(authenticationService.isAuthenticate());
        }, 1000);
    }, []);

    if (isAuthenticate) {
        return (
            <div>
                <UserDataSubjectRequest />
                <IconButton
                    menuProps={{
                        items: [
                            {
                                key: "email",
                                text: userData.email,
                            },
                            {
                                key: "signOut",
                                text: "Sign out",
                                onClick: props.logout,
                            },
                        ],
                        directionalHintFixed: true,
                    }}
                    iconProps={{ iconName: "UserFollowed", style: { fontSize: "24px" } }}
                    title="User"
                    ariaLabel="User"
                    styles={Styles.headerMenuIcon}
                />
            </div>
        );
    } else {
        return null;
    }
}

function UserDataSubjectRequest() {
    const [dsrData, setDsrData] = useState([{
        text: "Loading...",
    }]);

    useEffect(() => {
        const userData = authenticationService.currentUserValue;
        fetchDsrByEmail({ email: userData.email, top: 3 }).then((response) => {
            if (response.success) {
                const dsrDataItems = (
                    (response.data && response.data.dataSubjects) ||
                    []
                ).map((item) => {
                    const iconName =
                        item.requestType === REQUESTTYPES.DOWNLOAD
                            ? "CloudDownload"
                            : item.requestType === REQUESTTYPES.ERASE
                            ? "Delete"
                            : item.requestType === REQUESTTYPES.DONOTSELL
                            ? "HandsFree"
                            : item.requestType === REQUESTTYPES.CONSENT
                            ? "InfoSolid"
                            : "EditNote";
                    return {
                        key: item.id,
                        text: item.requestType,
                        secondaryText: new Date(item.createdDate).toLocaleString(),
                        iconProps: { iconName: iconName },
                    };
                });
                dsrDataItems.push({
                    key: "viewAll",
                    text: "View All",
                    onClick: () => {
                        window.location.href = "/datasubjectrequestlist";
                    },
                });
                setDsrData(dsrDataItems);
            }
        });
    }, []);

    return (
        <IconButton
            menuProps={{
                items: dsrData,
                directionalHintFixed: true,
            }}
            iconProps={{ iconName: "Ringer", style: { fontSize: "24px" } }}
            title="Notifications"
            ariaLabel="Notifications"
            styles={Styles.headerMenuIcon}
        />
    );
}
