import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Stack } from '@fluentui/react';
import { Styles as CommonStyles } from "../styles/common.styles";
import { Styles } from "../styles/footer.styles";

export class Footer extends Component {
    showCurrentYear() {
        return new Date().getFullYear();
    }
    render() {
        return (
            <Stack horizontal grow wrap style={Styles.footerMain} styles={Styles.footerMainStyles}>
                <Stack.Item>
                    <a href="https://skypointcloud.com" target="_blank" rel="noopener noreferrer">
                        <img alt="footer-logo" className="footer-img" src={"https://sicdpstorage.blob.core.windows.net/assets/skypoint-banner.png"} />
                    </a>
                </Stack.Item>
                <Stack.Item style={Styles.footerLinks}>
                    <Link to={{ pathname: "/aboutus" }} style={{...CommonStyles.navLink, color: window.secondaryColor}}>About us</Link>|
                    <a href={window.mainSiteUrl} style={{ color: window.secondaryColor, ...CommonStyles.navLink }} >Return to Main Site</a>
                </Stack.Item>
            </Stack>
        );
    }
}
