import API from "./API";

const serviceResponseWithEntityHandler = (response) => {
  if (!(response.data.isError)) {
    return {
        success: true,
        data: response.data.result
    };
  } else {
    return {
      success: false,
      message: response.data.responseException["exceptionMessage"]
    };
  }
};

const serviceErrorHandler = (error) => {
  console.error(error);
  return {
      success: false,
      error: error,
      data: error?.response?.data,
      message: error.message,
  };
};

export const fetchPrivacyCenterSettings = async () => {
    try {
    const response = await API().get(`/api/privacycenter/getorganizationsettings`);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchPrivacyContent = async () => {
  try {
    const response = await API().get(`/api/privacycenter/privacycontent`);
    return serviceResponseWithEntityHandler(response);
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchPreferences = async () => {
  try {
    const response = await API().get(`/api/privacycenter/preferences`);
    return {
        success: true,
        data: response.data.result
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchPrivacyActions = async () => {
  try {
    const response = await API().get(`/api/privacycenter/privacyaction`);
    return serviceResponseWithEntityHandler(response);
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchDataPractice = async () => {
  try {
    const response = await API().get(`/api/privacycenter/datapractice`);
    return serviceResponseWithEntityHandler(response);
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchTabConfiguration = async () => {
  try {
    const response = await API().get(`/api/privacycenter/tabconfiguration`);
    return {
        success: true,
        data: response.data.result
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchAboutus = async () => {
  try {
    const response = await API().get(`/api/privacycenter/aboutus`);
    return serviceResponseWithEntityHandler(response);
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchConsentManagement = async (subjectEmail) => {
  try {
    const [
      consentmanagement,
      dataprocessingactivities
    ] = await Promise.all([
      API().get(`/api/privacycenter/consentmanagement`, {
        params: {
          subjectEmail: subjectEmail
        }
      }),
      API().get(`/api/privacycenter/dataprocessingactivities`, {
        params: {
          subjectEmail: subjectEmail
        }
      }),
    ])
    return {
      success: true,
        data: {
            content: consentmanagement.data.result,
            dataProcessingActivities: dataprocessingactivities.data.result,
      }
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchInaccuracyMapping = async () => {
  try {
    const response = await API().get(`/api/privacycenter/getsavedinaccuracymapping`);
    return {
      success: true,
      data: response.data.result
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchDsrTypeOption = async () => {
  try {
    const response = await API().get(`/api/privacycenter/dsrtypeoption`);
    return {
      success: true,
      data: response.data.result
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const fetchCountries = async () => {
  try{
    const response = await API().get(`/api/privacycenter/countries`);
    return {
      success: true,
      data: response.data.result
    }
  } catch (error) {
    return serviceErrorHandler(error);
  }
}

export const fetchStatesByCountry = async (countryId) => {
  try{
    const response = await API().get(`/api/privacycenter/getstatesbycountry?countryId=${countryId}`);
    return {
      success: true,
      data: response.data.result
    }
  } catch (error) {
    return serviceErrorHandler(error);
  }
}

export const fetchCountryCallingInfo = async() => {
  try {
    const response = await API().get(`api/privacycenter/getcountriescallingcodes`)
    return {
      success: true,
      data: response.data.result
    }
  } catch (error){
    return serviceErrorHandler(error);
  }
}

export const fetchDsrByEmail = async (data) => {
  try {
    const response = await API().post(`/api/privacycenter/dsrbyemail`, data);
    return {
        success: true,
        data: response.data.result
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const cancelDataSubjectRequest = async (data) => {
  try {
    const response = await API().post(`/api/privacycenter/cancelrequest`, data);
    return {
        success: true,
        data: response.data
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const verifyEmail = async (id) => {
  try {
    const response = await API().post(`/api/privacycenter/verifyemail`, {
      id: id
    });
    return {
        success: true,
        data: response.data
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const saveConsentRequestData = async (data) => {
  try {
    const response = await API().post(`/api/privacycenter/consentrequest`, data);
    return {
        success: true,
        data: response.data
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};

export const saveDataSubjectRequest = async (data) => {
  try {
    const response = await API().post(`/api/privacycenter/dsrrequest`, data);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return serviceErrorHandler(error);
  }
};
