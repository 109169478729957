import { mergeStyles } from "@fluentui/react";

export const Styles = {
    detailsListClass: (customStyle) => {
        return mergeStyles({
            ".ms-DetailsRow": {
                height: "44px",
                ":hover, :focus": {
                    backgroundColor: "#f2f2f2",
                    background: "#f2f2f2",
                }
            },
            ".ms-DetailsRow-cell": {
                display: "flex",
                paddingTop: "8px",
                paddingBottom: "9px",
                alignSelf: "center",
                color: customStyle.color || "#646464",
            }
        })
    },
};
