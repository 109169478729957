import { Stack } from '@fluentui/react';
import React from 'react';

export class InactivePlan extends React.Component {

    componentDidMount() {
        document.body.classList.add("body-gray");
    }

    componentWillUnmount() {
        document.body.classList.remove("body-gray");
    }

    render() {

        return (
            <Stack>
                <Stack.Item align="center" styles={{ root: { padding: "20px 0px"  } }}>
                    <img alt="" src="https://sicdpstorage.blob.core.windows.net/sicdpb2c/Skypoint-logo-2x.png" />
                </Stack.Item>
                <Stack.Item align="center" className="sub-header">
                    Your company's SkyPoint Cloud account has been deactivated. You can easily reactivate your service by subscribing to SkyPoint Cloud Plan with <a href="https://app.skypointcloud.com/" rel="noopener noreferrer" target="_blank">Login</a> as a tenant administrator.
                    <br />Please email us at <a href="mailto:support@skypointcloud.com">support@skypointcloud.com</a>
                </Stack.Item>
            </Stack>
        );
    }
}
