import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Stack, Text, FontIcon } from "@fluentui/react";
import Loader from "./loader";
import { fetchAboutus } from '../services/privacy';
import { Styles as CommonStyles } from "../styles/common.styles";

export class AboutUs extends Component {
    static displayName = AboutUs.name;
    constructor() {
        super();
        this.getAboutUsContent = this.getAboutUsContent.bind(this);
        this.state = {
            aboutUsContent: "",
        };
    }

    componentDidMount() {
        this.getAboutUsContent();
    }

    async getAboutUsContent() {
        const newState = {
            isLoaded: true
        };
        const response = await fetchAboutus();
        if (response.success) {
            newState.aboutUsContent = response.data.htmlContent;
        }
        this.setState({ ...newState });
    }

    render() {
        const { aboutUsContent, isLoaded } = this.state;
        return (
            <Stack>
                <Stack.Item>
                    <Link to="/" style={CommonStyles.iconLink}>
                        <div>
                            <FontIcon
                                iconName="Back"
                                aria-hidden={true}
                                style={CommonStyles.backIcon}
                            />
                        </div>
                        <span>Back to Privacy Center</span>
                    </Link>
                    <Text variant="xxLarge">About us</Text>
                </Stack.Item>
                <Stack.Item>
                    {!isLoaded ? (
                        <Loader />
                    ) : (
                        <div
                            className="editorhtml"
                            style={CommonStyles.mt2}
                            dangerouslySetInnerHTML={{
                                __html: aboutUsContent,
                            }}
                        ></div>
                    )}
                </Stack.Item>
            </Stack>
        );
    }
}
