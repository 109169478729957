import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout';
import { Home } from './components/home';
import { AboutUs } from './components/aboutus';
import { DataSubjectRequest } from './components/datasubjectrequest';
import { VerifyRequest } from './components/verifyrequest';
import { ConsentManagement } from './components/consentmanagement';
import { DataSubjectRequestList } from './components/datasubjectrequestlist';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { InactivePlan } from './components/inactiveplan';
import { ThemeProvider } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { fetchPrivacyCenterSettings } from './services/privacy';
import Loader from './components/loader';
import { Styles as CommonStyles } from "./styles/common.styles";
initializeIcons();

export default class App extends Component {
    static displayName = App.name;

    constructor() {
        super();
        this.getSettings = this.getSettings.bind(this);
        this.state = {
            isAppLoaded: false,
            appTheme: {
                defaultFontStyle: {
                    fontFamily: 'Source Sans Pro',
                }
            }
        }
    }

    componentDidMount() {
        this.getSettings();
    }

    async getSettings() {
        const response = await fetchPrivacyCenterSettings();
        if (response.data && !(response.data.isError)) {
            var organizationSettings = response.data.result;
            window.primaryColor = organizationSettings.primaryColor;
            window.headerColor = organizationSettings.headerColor;
            window.secondaryColor = organizationSettings.secondaryColor;
            window.logoPath = organizationSettings.logoPath;
            window.mainSiteUrl = organizationSettings.mainSiteUrl;
            window.displayName = organizationSettings.displayName;
            window.privacyCenterHeadline = organizationSettings.privacyCenterHeadline;
            window.privacyCenterSubtitle = organizationSettings.privacyCenterSubtitle;
            this.setState({
                appTheme: {
                    ...this.state.appTheme,
                    palette: {
                        themePrimary: organizationSettings.primaryColor,
                        themeTertiary: organizationSettings.primaryColor,
                        themeSecondary: organizationSettings.primaryColor,
                        themeDarkAlt: organizationSettings.primaryColor,
                        themeDark: organizationSettings.primaryColor,
                        themeDarker: organizationSettings.primaryColor,
                        neutralPrimary: organizationSettings.secondaryColor,
                        neutralSecondary: organizationSettings.secondaryColor,
                        neutralPrimaryAlt: organizationSettings.secondaryColor,
                        neutralDark: organizationSettings.secondaryColor,
                    }
                }
            });

            const favicon = document.getElementById("favicon");
            favicon.href = organizationSettings.faviconIconPath ? organizationSettings.faviconIconPath : "#";
            if (organizationSettings.instrumentationKey && organizationSettings.instrumentationKey !== "") {
                const appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: organizationSettings.instrumentationKey
                    }
                });
                appInsights.loadAppInsights();
                appInsights.trackPageView();
            }            
        }
        else {
            window.isInactive = true;
        }
        this.setState({ isAppLoaded: true });
    }

    render () {
        return (
            <ThemeProvider applyTo="body" theme={this.state.appTheme}>
                {
                    this.state.isAppLoaded ?
                        window.isInactive ? <InactivePlan />
                            :
                            <Layout>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/aboutus' component={AboutUs} />
                                <Route exact path='/datasubjectrequest' component={DataSubjectRequest} />
                                <Route exact path='/consentmanagement' component={ConsentManagement} />
                                <Route exact path='/verifyrequest' component={VerifyRequest} />
                                <Route exact path='/datasubjectrequestlist' component={DataSubjectRequestList} />
                            </Layout> : <div style={CommonStyles.mainAppLoaderContainer}><Loader /></div>
                }
            </ThemeProvider>
        );
    }
}
