export const Styles = {
    footerMain:{
        background: "#ffffff",
        padding: "24px 32px",
        marginTop: "40px",
        borderTop: "1px solid #DEDEDE",
        alignItems: "center",
        position:"sticky",
        bottom:0
    },
    footerMainStyles:{
    root: {
        selectors: {
            '.ms-Stack-inner': {
                '@media(max-width: 900px)': {
                    justifyContent: "center"
                },
                '@media(min-width: 900px)': {
                    justifyContent:"space-between",
                },
                alignItems:"center",
                height: "70%"
            },
        },
    }
    },
    footerLinks:{
        fontSize: "15px",
        lineHeight: "24px"
    }
};
