export const _orderByKey = (arr : any[], key : string, reverse = false) : Array<any> => {
    if(!key)
        throw Error("Key cannot be null");

    const out = sortArray(arr, key);

    return reverse ? out.reverse() : out;
}

const sortArray = (arr : any[], key?: string) : Array<any> => {
    let result = [];

    if(key){
        result = arr.sort((a,b) => {
            if(a[key] === "" || a[key] === null) return 1;
            if(b[key] === "" || b[key] === null) return -1;
            if(a[key] === b[key]) return 0;
            return a[key] < b[key] ? -1 : 1;
        });
    }

    else{
        result = arr.sort((a,b) => {
            if(a === "" || a === null) return 1;
            if(b === "" || b === null) return -1;
            if(a === b) return 0;
            return a < b ? -1 : 1;
        }); 
    }

    return result;
}

export const validateFieldWithRegex = (data : string, regexString : string) : boolean => {
    const regex = new RegExp(regexString);
    return regex.test(data);
}