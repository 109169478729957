import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Styles as CommonStyles } from '../styles/common.styles';

class Loader extends React.Component {
    render() {
        return (
            <div style={CommonStyles.alignCenter}>
                <Spinner size={SpinnerSize.large} styles={CommonStyles.spinner} label="loading data..." />
            </div>
        )
    }
}

export default Loader