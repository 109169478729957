import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox, Dropdown, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField,CommandBar, 
    DatePicker} from "@fluentui/react";
import { authenticationService } from "../services/authenticationservice";
import { CONSENTTYPE, REQUESTTYPES } from "./constant";
import { Styles as CommonStyles } from "../styles/common.styles";
import { Styles } from "../styles/datasubjectrequest.styles";
import { fetchCountries, fetchCountryCallingInfo, fetchDsrTypeOption, fetchInaccuracyMapping, fetchStatesByCountry, saveDataSubjectRequest } from '../services/privacy';
import { _orderByKey, validateFieldWithRegex }  from "../utils/helper";

export class DataSubjectRequest extends Component {
    static displayName = DataSubjectRequest.name;
    constructor() {
        super();
        this.backToPrivacyCenter = this.backToPrivacyCenter.bind(this);
        this.saveRequest = this.saveRequest.bind(this);
        this.handleStaticInputFieldsChanges = this.handleStaticInputFieldsChanges.bind(this);
        this.handleDynamicInputFieldsChanges = this.handleDynamicInputFieldsChanges.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCategoryInputChange = this.handleCategoryInputChange.bind(this);
        this.fetchDSRTypes = this.fetchDSRTypes.bind(this);
        this.fetchStatesByCountry = this.fetchStatesByCountry.bind(this);
        this.fetchCountryCallingCodes = this.fetchCountryCallingCodes.bind(this);
        this.fetchCountriesByDomain = this.fetchCountriesByDomain.bind(this);
        this.fetchInaccuracyMappingsList = this.fetchInaccuracyMappingsList.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleInaccuracyDropdownChange = this.handleInaccuracyDropdownChange.bind(this);

        let userData = authenticationService.currentUserValue;
        this.state = {
            isAuthenticate: userData ? true : false,
            isLoaded: false,
            actionName: "",
            successMessage: null,
            errorMessage: null,
            email: userData ? userData.email : "",
            firstName : "",
            lastName : "",
            requestDetails : "",
            residentType: userData ? userData.residentType : null,
            subjectType: userData ? userData.subjectType : null,
            isRunning: false,
            subjectOptions: [],
            residencyOptions: [],
            isReCaptchaVerify: false,
            categories: [],
            categoryOptions: [],
            country: {
                options: [],
                loaded: false,
            },
            states: {
                options: [],
                loaded: false
            },
            countryCallingCodes: {
                options: [],
                loaded: false
            },
            categoryHeader: "",
            inaccuracyMappings : [],
            inaccuracyMappingsValues : {},
            mappingsValuesErrors: {}
        }
    }

    PHONE_REGEX = "^\\+(?:[0-9] ?){6,14}[0-9]$";

    async componentDidMount() {
        if (this.props.location.state == null) {
            this.backToPrivacyCenter();
            return;
        }
        const actionName = this.props.location.state.actionName;
        this.setState({ actionName: actionName });

        this.fetchInaccuracyMappingsList();
        this.fetchCountriesByDomain();
        this.fetchCountryCallingCodes();
        this.fetchDSRTypes();
    }
    
    fetchInaccuracyMappingsList(){
        fetchInaccuracyMapping()
        .then(response => {
            if(response.success){
                this.setState({ inaccuracyMappings : _orderByKey(response.data.inaccuracyMappings, "order") });
            }
        })
    }

    async fetchCountriesByDomain() {
        const response = await fetchCountries();
        if(response.success){
            const hasPriorityCountry = true;   //  CONFIG
            const priorityCountry = "United States";    //  CONFIG
            const data = response.data;
            let priorityCountryIndex = null;
            let priorityCountryData = null;
            const tempOptions = data.map((item, index) => {
                if(priorityCountryIndex === null && hasPriorityCountry && priorityCountry === item.name){
                    priorityCountryIndex = index;
                    priorityCountryData = { key: item.id, text: item.name, abbreviation: item.abbreviation };
                }
                return { key: item.id, text: item.name, abbreviation: item.abbreviation };
            });
            if(priorityCountryIndex !== null) {
                tempOptions.splice(priorityCountryIndex, 1);
                tempOptions.unshift(priorityCountryData);
            }
            this.setState({ country : { options : tempOptions, loaded : true } });
        }
    }

    async fetchStatesByCountry(countryId) {
        const response = await fetchStatesByCountry(countryId);
        if(response.success) {
            const data = response.data;
            const tempOptions = data.map((item) => { return { key: item.code, text: item.name }});
            this.setState({ states: { options : tempOptions, loaded : true }});
        }
    }

    async fetchCountryCallingCodes() {
        const response = await fetchCountryCallingInfo();
        if(response.success){
            const data = response.data;
            const tempOptions = data.map((item) => { return { key: item.name, text: item.dialCode }});
            this.setState({ countryCallingCodes : {options: tempOptions, loaded: true }});
        }
    }

    async fetchDSRTypes() {
        const newState = {
            isLoaded : true
        }
        const response = await fetchDsrTypeOption();
        if (response.success) {
            const data = response.data;
            const firstSubjectOption = data && data.subjectTypes && data.subjectTypes.length > 0 ? data.subjectTypes[0].id : '';
            const firstResidencyOption = data && data.residencyTypes && data.residencyTypes.length > 0 ? data.residencyTypes[0].id : '';
            const header = data && data.dsrDoNotSellCategory ? data.dsrDoNotSellCategory.header : '';
            const categoryOptions = data && data.dsrDoNotSellCategory ? data.dsrDoNotSellCategory.categories : [];

            if (!authenticationService.isAuthenticate()) {
                newState.subjectType = firstSubjectOption;
                newState.residentType = firstResidencyOption;
            }

            newState.subjectOptions = (data && data.subjectTypes)
                ? data.subjectTypes.map((item) => { return { key: item.id, text: item.name } })
                : [];
            newState.residencyOptions =  (data && data.residencyTypes)
                ? data.residencyTypes.map((item) => { return { key: item.id, text: item.name } })
                : [];
            newState.categoryOptions = categoryOptions;
            newState.categoryHeader = header;
        }
        this.setState({ ...newState });
    }

    handleCategoryInputChange(row, checked) {
        let categories = this.state.categories;
        if (!categories)
            categories = [];
        if (checked) {
            categories.push(row.id);
        }
        else {
            let index = categories.indexOf(row.id);
            if (index > -1) {
                categories.splice(index, 1);
            }
        }
        this.setState({ categories: categories });
    }

    handleRadioChange(name, option) {
        this.setState({
            [name]: option.key
        });
    }

    handleInaccuracyDropdownChange(item, option) {
        const name = item.label;
        const value = option?.text;
        this.setState(prevState => ({
            inaccuracyMappingsValues  : {
                ...prevState.inaccuracyMappingsValues,
                [name] : value
            }
        }));

        if(item.additionalSettings?.data){
            if(name === "Location.Country" && (this.state.inaccuracyMappingsValues[name] !== value || !this.state.inaccuracyMappingsValues[name])){
                const callingCodeOptions = this.state.countryCallingCodes.options;
                const selectedCountryAbbr = this.state.country.options?.find(i => i.text === value)?.abbreviation;
                const selectedCC = callingCodeOptions.find(i => i.key === value);
                this.setState({states : { options : [], loaded : false }});
                this.setState(prevState => ({
                    inaccuracyMappingsValues : {
                        ...prevState.inaccuracyMappingsValues,
                        "Location.State" : "",
                        "Country.CallingCode" : selectedCC?.text ?? "",
                        "Country.Abbreviation" : selectedCountryAbbr ?? "",
                    }
                }));
                this.fetchStatesByCountry(String(option.key));
            }
        }

        if(name === "Country.CallingCode" && value){
            this.setState(prevState => ({
                mappingsValuesErrors: {
                    ...prevState.mappingsValuesErrors,
                    "Identity.Service.Phone" : null
                }
            }));
        }
    }

    handleStaticInputFieldsChanges(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name] : value
        });
    }

    handleDynamicInputFieldsChanges(e, regexValidationString){
        const name = e.target.name;
        const value = e.target.value;

        if(name === "Identity.Service.Phone"){
            const inaccMappingValues = this.state.inaccuracyMappingsValues;
            const dynamicValuesHasCountryCode = Object.prototype.hasOwnProperty.call(inaccMappingValues, "Country.CallingCode");
            
            if(dynamicValuesHasCountryCode && !["",undefined,null].includes(inaccMappingValues["Country.CallingCode"])){
                this.setState(prevState => ({
                    inaccuracyMappingsValues : {
                      ...prevState.inaccuracyMappingsValues,
                        [name] : value?.trim()
                    },
                    mappingsValuesErrors: {
                        ...prevState.mappingsValuesErrors,
                        [name] : null
                    }
                }));
            }

            else{
                this.setState(prevState => ({
                    inaccuracyMappingsValues : {
                      ...prevState.inaccuracyMappingsValues,
                        [name] : ""
                    },
                    mappingsValuesErrors: {
                        ...prevState.mappingsValuesErrors,
                        [name] : "Country calling code field is empty"
                    }
                }));
            }
        }

        else if(name === "Country.CallingCode" && value){
            this.setState(prevState => ({
                inaccuracyMappingsValues : {
                  ...prevState.inaccuracyMappingsValues,
                    [name] : value?.trim()
                },
                mappingsValuesErrors: {
                    ...prevState.mappingsValuesErrors,
                    "Identity.Service.Phone" : null,
                }
            }));
        }

        else{
            this.setState(prevState => ({
                inaccuracyMappingsValues : {
                  ...prevState.inaccuracyMappingsValues,
                    [name] : value
                },
            }));
        }

        if(regexValidationString){
            this.setState(prevState => ({
                mappingsValuesErrors: {
                    ...prevState.mappingsValuesErrors,
                    [name] : validateFieldWithRegex(value, regexValidationString) ? null : "Input format is not valid"
                }
            }));
        }
    }

    handleDateChange (item, date) {
        const formattedDate = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
        this.setState(prevState => ({
            inaccuracyMappingsValues : {
              ...prevState.inaccuracyMappingsValues,
                [item.label] : formattedDate
            }
        }));
    }

    backToPrivacyCenter() {
        this.props.history.replace({
            pathname: "/"
        });
    }

    async saveRequest(e) {
        let categories = this.state.categories;
        const updatedInaccuracies = this.state.inaccuracyMappingsValues;
        const mappingsValuesErrors = Object.values(this.state.mappingsValuesErrors).filter(x => x);

        if (this.state.actionName === REQUESTTYPES.DONOTSELL) {
            if (!categories || categories.length === 0) {
                this.setState({
                    errorMessage: "Please select at least one category"
                }, () => setTimeout(() => {
                    this.setState({ errorMessage: null });
                }, 10000));
                return;
            }
        }
        else {
            categories = [];
        }
        
        e.preventDefault();
        Object.keys(updatedInaccuracies).forEach(key => {
            if (updatedInaccuracies[key].trim() === "")
                delete updatedInaccuracies[key];
        });

        // no save if any regex field errors present
        if(mappingsValuesErrors.length > 0){
            return;
        }

        // no save if phone field present but no country calling code
        if(updatedInaccuracies["Identity.Service.Phone"] && !updatedInaccuracies["Country.CallingCode"]){
            this.setState(prevState => ({
                mappingsValuesErrors: {
                    ...prevState.mappingsValuesErrors,
                    "Identity.Service.Phone" : "Country calling code field is empty"
                }
            }));

            return;
        }

        this.setState({ isRunning: true });

        if(updatedInaccuracies["Identity.Service.Phone"] && !["", null, undefined].includes(updatedInaccuracies["Country.CallingCode"])){
            
            if(!validateFieldWithRegex(updatedInaccuracies["Identity.Service.Phone"], this.PHONE_REGEX)){
                updatedInaccuracies["Identity.Service.Phone"] = updatedInaccuracies["Country.CallingCode"] + 
                    updatedInaccuracies["Identity.Service.Phone"];
            }
        }

        const model = {
            residentType: this.state.residentType,
            email: this.state.email,
            requestType: this.state.actionName,
            subjectType: this.state.subjectType,
            categories: categories,
            ...(this.state.actionName !== REQUESTTYPES.INACCURACIES && { 
                firstName : this.state.firstName,
                lastName : this.state.lastName,
                requestDetails : this.state.requestDetails
            }),
            ...(this.state.actionName === REQUESTTYPES.INACCURACIES && { updatedFields : {
                ...updatedInaccuracies
            }})
        }

        const response = await saveDataSubjectRequest({ dataSubject: model });
        if (response.success) {
            if (!(response.data.isError)) {
                const requestData = response.data.result;
                if (requestData && requestData.requestType === CONSENTTYPE && this.state.isAuthenticate) {
                    this.props.history.replace({
                        pathname: '/consentmanagement',
                        state: { subjectEmail: requestData.email, dataSubjectRequestId: requestData.id }
                    });
                }
                else {
                    this.setState({
                        successMessage: response.data.message
                    }, () => setTimeout(() => {
                        this.setState({ successMessage: null });
                    }, 10000));
                    const message = this.state.isAuthenticate ? `Your ${this.state.actionName} privacy request has been submitted successfully.` : `In order to process your ${this.state.actionName} privacy request, we need to verify your identity by verifying your email. Please note that you may need to check your spam or junk email folder. You have 24 hours to click the link in the message to confirm your email address.`;
                    this.props.history.replace({
                        pathname: '/',
                        state: { message: message}
                    });
                }
            }
            else {
                this.setState({
                    errorMessage: response.data.responseException["exceptionMessage"]
                }, () => setTimeout(() => {
                    this.setState({ errorMessage: null });
                }, 10000));
            }
        }
        else {
            this.setState({
                errorMessage: response.data.responseException["exceptionMessage"]
            }, () => setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 10000));
        }
        this.setState({ isRunning: false });
    }

    onChange = (value) => {
        this.setState({ isReCaptchaVerify : true })
    }
    
    getDynamicInaccuracyItemState = (item) => {
        switch(item.label){
            case "Location.Country":
                return this.state.country;

            case "Country.CallingCode":
                return this.state.countryCallingCodes;

            case "Location.State":
                return this.state.states;

            default:
                return null;
        }
    }

    render() {
        
        const {
            categories,
            categoryHeader,
            categoryOptions,
            isReCaptchaVerify,
            isRunning,
            inaccuracyMappings,
            inaccuracyMappingsValues,
            email,
            firstName,
            lastName,
            requestDetails,
            residentType,
            actionName,
            isAuthenticate,
            subjectType,
            subjectOptions,
            residencyOptions,
            successMessage,
            errorMessage,
            mappingsValuesErrors
        } = this.state;

        return (
            <Stack styles={Styles.mainContainer}>
                <Stack.Item>
                    <CommandBar
                        items={[
                            {
                                key: 'back',
                                text: 'Back to Home',
                                iconProps: {iconName: 'Back', style: { color: '#000' }},
                                onClick: () => this.props.history.push("/"),
                            },
                        ]}
                        styles={Styles.backButton}
                    />
                    <div style={Styles.dataSubjectRequestHeader}>
                        <Text variant="xxLarge" style={Styles.dataSubjectRequestHeaderText}>{actionName} Request</Text>
                    </div>
                    <div style={CommonStyles.mt2}>
                        {successMessage && (
                            <MessageBar messageBarType={MessageBarType.success}>
                                {successMessage}
                            </MessageBar>
                        )}
                        {errorMessage && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {errorMessage}
                            </MessageBar>
                        )}
                    </div>
                </Stack.Item>
                <Stack.Item>
                    {   
                        <form
                            ref="form"
                            id="saverequest"
                            onSubmit={this.saveRequest}
                        >
                            <ReCAPTCHA
                                sitekey="6Ldfkb8UAAAAALS9dngp1jU_E9kC91vbKvYV0UQd"
                                onChange={this.onChange}
                            >
                                {!isAuthenticate &&
                                    <Stack styles={{root: {width: 296}}} tokens={{ childrenGap: 16 }}>
                                        {residencyOptions &&
                                        residencyOptions.length > 0 && (
                                            <Dropdown
                                                label="I reside in"
                                                name="residentType"
                                                options={residencyOptions}
                                                placeholder="Select one"
                                                selectedKey={residentType}
                                                className={Styles.dropdownGroupClass}
                                                onChange={(e, option) => this.handleRadioChange("residentType", option)}
                                                required={true}
                                            />)}
                                        {subjectOptions &&
                                        subjectOptions.length > 0 && (
                                            <Dropdown
                                                label="I am a (an)"
                                                name="subjectType"
                                                options={subjectOptions}
                                                className={Styles.dropdownGroupClass}
                                                placeholder="Select one"
                                                selectedKey={subjectType}
                                                onChange={(e, option) => this.handleRadioChange("subjectType", option)}
                                                required={true}
                                            />)}
                                    </Stack>}

                                {actionName === REQUESTTYPES.DONOTSELL && (
                                    <Stack tokens={{ childrenGap: 22 }}>
                                        <Text styles={Styles.categoryHeader}>
                                            {categoryHeader}{" "}
                                            <span>*</span>
                                        </Text>
                                        {categoryOptions &&
                                            categoryOptions.map((row) => (
                                                <Checkbox
                                                    key={"cbk" + row.id}
                                                    label={row.name}
                                                    checked={
                                                        categories &&
                                                        !!categories.find(
                                                            (e) => e === row.id
                                                        )
                                                    }
                                                    name="categories"
                                                    onChange={(e, checked) => this.handleCategoryInputChange(row, checked)}
                                                />
                                            ))}
                                    </Stack>
                                )}

                                {!isAuthenticate && (
                                    <Stack styles={Styles.textFieldsStyles} tokens={{ childrenGap: 16 }}>
                                    <TextField
                                        type="email"
                                        name="email"
                                        label="Email"
                                        value={email}
                                        onChange={this.handleStaticInputFieldsChanges}
                                        required
                                    />
                                    {actionName === REQUESTTYPES.INACCURACIES ?
                                        <>
                                            {
                                                inaccuracyMappings.filter(item => item.isSelected).map(item => {
                                                    const options = this.getDynamicInaccuracyItemState(item)?.options;
                                                    return (item.dataType === "date" || item.label === "Person.BirthDate") ? 
                                                    <DatePicker
                                                        placeholder="Select a date..."
                                                        ariaLabel="Select a date"
                                                        label={item.empowerLabel}
                                                        key={item.label}
                                                        maxDate={new Date()}
                                                        onSelectDate={date => this.handleDateChange(item, date)}
                                                    /> : 
                                                    (item.additionalSettings?.data) ? (
                                                        !this.getDynamicInaccuracyItemState(item) ? 
                                                        <TextField
                                                            name= {item.label}
                                                            label={item.empowerLabel}
                                                            value={inaccuracyMappingsValues[item.label]}
                                                            key={item.label}
                                                            onChange={(e) => this.handleDynamicInputFieldsChanges(e,item.additionalSettings.regexValidation)}
                                                        /> : 
                                                        <Dropdown
                                                            label={item.empowerLabel}
                                                            name={item.label}
                                                            key={item.label}
                                                            options={options}
                                                            className={Styles.dropdownGroupClass}
                                                            placeholder="Select one"
                                                            selectedKey={
                                                                options.find((i) => i.text === inaccuracyMappingsValues[item.label])?.key
                                                            }
                                                            onChange={(e, option) => this.handleInaccuracyDropdownChange(item, option)}
                                                            required={false}
                                                        />
                                                    ) : (item.additionalSettings?.metadataData?.length > 0) ? (
                                                        <Dropdown
                                                            label={item.empowerLabel}
                                                            name={item.label}
                                                            key={item.label}
                                                            options={item.additionalSettings.metadataData.map((val, idx) => {
                                                                return {
                                                                    key: idx,
                                                                    text: val
                                                                } 
                                                            })}
                                                            className={Styles.dropdownGroupClass}
                                                            placeholder="Select one"
                                                            selectedKey={item.value}
                                                            onChange={(e, option) => this.handleInaccuracyDropdownChange(item, option)}
                                                            required={false}
                                                        />
                                                    ) :
                                                    <TextField
                                                        name= {item.label}
                                                        label={item.empowerLabel}
                                                        value={inaccuracyMappingsValues[item.label]}
                                                        key={item.label}
                                                        onChange={(e) => this.handleDynamicInputFieldsChanges(e,item.additionalSettings.regexValidation)}
                                                        errorMessage={
                                                            mappingsValuesErrors[item.label]
                                                        }
                                                    />
                                                })
                                            }
                                        </> : 
                                        <>
                                            <TextField
                                                type="firstName"
                                                name="firstName"
                                                label="First name"
                                                value={firstName}
                                                onChange={this.handleStaticInputFieldsChanges}
                                                required
                                            />
                                            <TextField
                                                type="lastName"
                                                name="lastName"
                                                label="Last name"
                                                value={lastName}
                                                onChange={this.handleStaticInputFieldsChanges}
                                                required
                                            />
                                            <Stack styles={Styles.messageTextFields}>
                                                <TextField
                                                    name="requestDetails"
                                                    label="Request Details"
                                                    value={requestDetails}
                                                    rows={5}
                                                    multiline
                                                    autoAdjustHeight
                                                    onChange={this.handleStaticInputFieldsChanges}
                                                    required
                                                />
                                            </Stack>
                                        </>}
                                    </Stack>
                                )}
                                <br />
                            </ReCAPTCHA>
                            <Stack styles={Styles.acknowledgement}>
                                <Text
                                    variant="medium"
                                    style={Styles.acknowledgementHeader}
                                    block
                                >
                                    Acknowledgement
                                </Text>
                                <Text style={Styles.acknowledgementText}>
                                    By submitting this form, I confirm that the
                                    information I have provided is true and correct.
                                    I acknowledge that {window.displayName} is
                                    collecting this information and will use it in
                                    accordance with its privacy policy.
                                </Text>
                            </Stack>
                            {
                                Object.values(mappingsValuesErrors).filter(x => x).length > 0 ? 
                                <Stack style={{marginTop: "12px", width: "60%"}}>
                                    <MessageBar messageBarType={MessageBarType.error}>
                                        Some of the above input fields have data in invalid format. Please verify.
                                    </MessageBar>
                                </Stack> : 
                                <></>
                            }
                            <PrimaryButton
                                type="submit"
                                name="btnsave"
                                disabled={
                                    isRunning || !isReCaptchaVerify || 
                                    (Object.values(mappingsValuesErrors).filter(x => x).length > 0)
                                }
                                text="Submit Request"
                                className={Styles.primaryButton({
                                    backgroundColor: window.primaryColor,
                                    color: "#fff",
                                })}
                            />
                        </form>
                    }
                    
                </Stack.Item>
            </Stack>
        );
    }
}
