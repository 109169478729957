import { mergeStyles, mergeStyleSets } from "@fluentui/react";

export const Styles = {
    mainAppLoaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        height: "100%",
        width: "100%",
    },
    iconLink: {
        display: "flex",
        alignItems: "center",
    },
    backIcon: {
        marginRight: "10px",
    },
    navLink: {
        padding: "8px 8px",
        textDecoration:"none"
    },
    alignCenter: {
        textAlign: "center",
    },
    textDanger: {
        color: "red",
    },
    spinner: mergeStyleSets({
        circle: {
            height: "50px",
            width: "50px",
        },
    }),
    primaryButton: (customStyle) => {
        return mergeStyles({
            backgroundColor: customStyle.backgroundColor,
            borderColor: customStyle.backgroundColor,
            color: customStyle.color,
            ":hover, :focus": {
                backgroundColor: customStyle.backgroundColor,
                borderColor: customStyle.backgroundColor,
            },
            "&:disabled, &[disabled]": {
                opacity: 0.7,
                cursor: "not-allowed",
            },
        });
    },
    mt2: {
        marginTop: "20px",
    },
};
