import { mergeStyles } from "@fluentui/react";

export const Styles = {
    mainContainer:{
      root:{
            marginTop: "-44px",
      selectors:{
          ".ms-Label": {
              fontWeight:400,
              ":after": {
                  color:"#000000",
              }
        }}
        }
    },
    dataSubjectRequestHeader:{
        display:"flex",
        justifyContent:"center",
        fontWeight:"bold",
        color:"#191919",
    },
    dataSubjectRequestHeaderText:{
        fontSize:"36px",
        fontWeight:"bold",
        textAlign:"center"
    },
    backButton:{
        root: {padding:0,
            selectors: {
                '.ms-Button': {
                    padding: 0,
                    marginLeft:"-3px",
                    ":hover": {
                        background:"#ffffff",
                    }
                }
            }
        }
    },
    textFieldsStyles:{
        root: {width: 296, marginTop:"20px"}
    },
    messageTextFields:{
        root: {
            '@media(min-width: 900px)': {
                width: 612,
            },
            marginTop:"16px"
        }
    },
    dropdownGroupClass: mergeStyles({
        ".ms-ChoiceFieldGroup-flexContainer": {
            display: "flex",
        },
        ".ms-ChoiceField": {
            marginRight: "10px"
        },
    }),
    primaryButton: (customStyle) => {
        return mergeStyles({
            backgroundColor: customStyle.backgroundColor,
            color: customStyle.color,
            width: "120px",
            fontSize:"14px",
            lineHeight:"20px",
            padding:"0",
            marginTop: "20px",
            borderRadius: "2px",
            "&:disabled, &[disabled]": {
                cursor: "not-allowed",
            },
        });
    },
    acknowledgement:{
        root:{
        marginTop: "20px",
        '@media(min-width: 900px)': {
            width: 678
        },
        }
    },
    acknowledgementHeader:{
        fontWeight:"bold",
        color:"#191919"
    },
    acknowledgementText:{
        marginTop:"8px",
        lineHeight: "24px",
        color:"#191919"
    },
    categoryHeader:{
       root:{ marginTop: "16px",
        '@media(min-width: 900px)': {
            width: "678px"
        },
        '@media(max-width: 500px)': {
            width: "296px"
        },
        lineHeight:"24px"
       }
    },
};
