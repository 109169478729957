import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    getHeader,
    isAuthenticate,
    getURL,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(user) {
    axios.post(`/api/privacycenter/login`, user, {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    }).then(response => {
        localStorage.setItem('currentUser', JSON.stringify(response.data.result))
        currentUserSubject.next(response.data.result);
    }).catch((error) => {
        console.log(error);
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function isAuthenticate() {
    return authenticationService.currentUserValue ? true : false;
}

function getURL(url) {
    return process.env.REACT_APP_API_BASE_URL + url;
}

function getHeader() {
    return {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        "Authorization": `Bearer ${authenticationService.currentUserValue ? authenticationService.currentUserValue.token : ''}`
    };
}