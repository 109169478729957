import React, { Component } from 'react';
import { Header } from './header';
import { Footer } from './footer'

export class Layout extends Component {
  static displayName = Layout.name;


  render () {
      return (
          <React.Fragment>
              <Header />
              <div className="main-container">{this.props.children}</div>
              <Footer />
          </React.Fragment>
    );
  }
}
