
export const TABNAMES = {
    POLICY: "Policy",
    ACTIONS: "Actions",
    PREFERENCES: "Preferences",
    DATAPRACTICES: "DataPractices"
}

export const REQUESTTYPES = {
    DOWNLOAD: "Download My Data",
    CONSENT: "Consent",
    ERASE: "Erase Me",
    DONOTSELL: "Do Not Sell My Data",
    INACCURACIES: "Update Inaccuracies"
};

export const CONSENTTYPE = "Consent";

export const DSR_STATUS = {
    REQUESTED: "Requested",
    INREVIEW: "InReview",
    PROCESSING: "Processing",
    COMPLETE: "Complete",
    REJECT: "Reject",
    CONSENTED: "Consented",
    CANCELED: "Canceled",
}

export const CANCELABLE_DSR_STATUS = [DSR_STATUS.REQUESTED, DSR_STATUS.INREVIEW];
