import React, { Component } from "react";
import Loader from "./loader";
import {
    Stack,
    Pivot,
    PivotItem,
    DocumentCard,
    FontIcon,
    Toggle,
    TooltipHost,
    DirectionalHint,
    Text,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    DefaultButton,
} from "@fluentui/react";
import cookie from "react-cookies";
import {
    fetchDataPractice,
    fetchPreferences,
    fetchPrivacyActions,
    fetchPrivacyContent,
    fetchTabConfiguration,
} from "../services/privacy";
import { TABNAMES, REQUESTTYPES } from "./constant";
import { Styles } from "../styles/home.styles";
import { Styles as CommonStyles } from "../styles/common.styles";
import { ReactComponent as DownloadMyDataIcon } from "../assets/img/Download-My-Data.svg";
import { ReactComponent as ConsentIcon } from "../assets/img/Consent.svg";
import { ReactComponent as EriseIcon } from "../assets/img/Erise.svg";
import { ReactComponent as SellMyDataIcon } from "../assets/img/Sell-My-Data.svg";
import { ReactComponent as UpdateIcon } from "../assets/img/Update.svg";

export class Home extends Component {
    static displayName = Home.name;

    constructor() {
        super();
        this.getPrivacyContent = this.getPrivacyContent.bind(this);
        this.getPrivacyCenterAction = this.getPrivacyCenterAction.bind(this);
        this.getDataPracticeContent = this.getDataPracticeContent.bind(this);
        this.getPreferences = this.getPreferences.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSwitchInputChange = this.handleSwitchInputChange.bind(this);
        this.savePreferences = this.savePreferences.bind(this);
        this.redirectToDataSubjectRequest =
            this.redirectToDataSubjectRequest.bind(this);
        this.state = {
            privacyContent: "",
            dataPractice: "",
            successMessage: null,
            successMessageMain: null,
            isDataPracticeLoaded: false,
            actions: null,
            isPrivacyPolicyLoaded: false,
            preferences: [],
            isPrivacyActionLoaded: false,
            isRunning: false,
            tabConfigData: [],
            isTabConfigLoaded: false,
        };
    }

    componentDidMount() {
        if (
            this.props.location.state != null &&
            this.props.location.state.message != null
        ) {
            this.setState(
                {
                    successMessageMain: this.props.location.state.message,
                },
                () =>
                    setTimeout(() => {
                        this.setState({ successMessageMain: null });
                    }, 10000)
            );
        }
        this.getTabConfiguration();
    }

    async getTabConfiguration() {
        const response = await fetchTabConfiguration();
        if (response.success) {
            var firstTab = response.data
                ? response.data[0].name
                : TABNAMES.POLICY;
            this.setState(
                { tabConfigData: response.data, isTabConfigLoaded: true },
                () => {
                    this.handleSelect(firstTab);
                }
            );
        } else {
            this.setState({ isTabConfigLoaded: true });
        }
    }

    async getPrivacyContent() {
        if (!this.state.isPrivacyPolicyLoaded) {
            const newState = {
                isPrivacyPolicyLoaded: true,
            };
            const response = await fetchPrivacyContent();
            if (response.success) {
                newState.privacyContent = response.data.htmlContent;
            }
            this.setState({ ...newState });
        }
    }

    async getDataPracticeContent() {
        if (!this.state.isDataPracticeLoaded) {
            const newState = {
                isDataPracticeLoaded: true,
            };
            const response = await fetchDataPractice();
            if (response.success) {
                newState.dataPractice = response.data.htmlContent;
            }
            this.setState({ ...newState });
        }
    }

    async getPrivacyCenterAction() {
        if (!this.state.isPrivacyActionLoaded) {
            const newState = {
                isPrivacyActionLoaded: true,
            };
            const response = await fetchPrivacyActions();
            if (response.success) {
                newState.actions = response.data;
            }
            this.setState({ ...newState });
        }
    }

    async getPreferences() {
        var preferences = cookie.load("preferences");
        const newState = {
            isPreferencesLoaded: true,
        };
        const response = await fetchPreferences();
        newState.preferences = response.data;
        if (response.success && preferences != null && newState.preferences != null) {
            newState.preferences.forEach(
                (t) =>
                    (t.isEnable = preferences.find((e) => e.id === t.id)
                        ? preferences.find((e) => e.id === t.id).isEnable
                        : t.isEnable)
            );
        }
        this.setState({ ...newState });
    }

    handleSelect(key) {
        if (key === TABNAMES.ACTIONS) {
            this.getPrivacyCenterAction();
        } else if (key === TABNAMES.PREFERENCES) {
            this.getPreferences();
        } else if (key === TABNAMES.DATAPRACTICES) {
            this.getDataPracticeContent();
        } else if (key === TABNAMES.POLICY) {
            this.getPrivacyContent();
        }
    }

    redirectToDataSubjectRequest(actionName) {
        this.props.history.replace({
            pathname: "/datasubjectrequest",
            state: { actionName: actionName },
        });
    }

    handleSwitchInputChange(data, checked) {
        var preferences = this.state.preferences;
        var preference = preferences.find((t) => t.id === data.id);
        if (preference) {
            preference.isEnable = checked;
        }
        this.setState({ preferences: preferences });
    }

    savePreferences(e) {
        var model = this.state.preferences;
        cookie.remove("preferences", model, { path: "/" });
        cookie.save("preferences", model, { path: "/" });
        this.setState(
            {
                successMessage: "Preferences saved successfully.",
            },
            () =>
                setTimeout(() => {
                    this.setState({ successMessage: null });
                }, 10000)
        );
    }

    getTabData = (t) => {
        if (t.name === TABNAMES.PREFERENCES) {
            const {
                preferences,
                isPreferencesLoaded,
                isRunning,
                successMessage,
            } = this.state;
            return (
                <React.Fragment>
                    <div style={CommonStyles.mt2}></div>
                    {successMessage && (
                        <MessageBar messageBarType={MessageBarType.success}>
                            {successMessage}
                        </MessageBar>
                    )}
                    <Stack>
                    <Text variant="xxLarge" block styles={Styles.preferenceHeaderText}>
                        You control your data.
                    </Text>
                    <Text variant="mediumPlus" styles={Styles.preferenceSubtext} block>
                        Our products are designed to protect your privacy. We
                        won't collect data about you for advertising, analytics,
                        or other non-essential things unless you give us
                        permission to. You can change your preferences at any
                        time from this window or from our Privacy Center.
                    </Text>
                    </Stack>
                    {isPreferencesLoaded ? (
                        <React.Fragment>
                            {preferences &&
                                preferences.map((item) => (
                                    <Stack
                                        key={"preference_" + item.id}
                                        tokens={{ childrenGap: 10 }}
                                    >
                                        <Stack.Item>
                                            <Toggle
                                                onText="On"
                                                offText="Off"
                                                styles={Styles.preferenceToggle}
                                                label={
                                                    <div style={{ fontSize: 14 }}>
                                                        <span>{item.name}</span>
                                                        <TooltipHost
                                                            directionalHint={DirectionalHint.rightCenter}
                                                            content={item.description}
                                                            aria-hidden={true}
                                                            tooltipProps={Styles.preferenceTooltip}
                                                        >
                                                            <FontIcon
                                                                iconName="Info"
                                                                style={{
                                                                    ...Styles.preferenceIcons,
                                                                    color: window.secondaryColor,
                                                                }}
                                                            />
                                                        </TooltipHost>
                                                    </div>
                                                }
                                                onChange={(e, checked) => this.handleSwitchInputChange(item, checked)}
                                                defaultChecked={item.isEnable}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                ))}
                            <Stack style={{ marginTop: "30px", width:"125px" }}>
                                <PrimaryButton
                                    text="Save Preferences"
                                    onClick={this.savePreferences}
                                    allowDisabledFocus
                                    disabled={isRunning}
                                    styles={{root:{padding:0}}}
                                />
                            </Stack>
                        </React.Fragment>
                    ) : (
                        <div className="col-md-12 text-center">
                            <Loader />
                        </div>
                    )}
                </React.Fragment>
            );
        } else if (t.name === TABNAMES.ACTIONS) {
            const { isPrivacyActionLoaded, actions } = this.state;
            return (
                <Stack horizontal wrap tokens={{ childrenGap: 30 }} styles={Styles.ActionCard}>
                    {isPrivacyActionLoaded ? (
                        <React.Fragment>
                            {actions["downloadEnable"] && (
                                <Stack.Item>
                                    <DocumentCard className={Styles.ActionCardWrapper({
                                        color: window.primaryColor
                                    })}>
                                        <div style={Styles.ActionCardDetailsWrapper}>
                                            <Stack className={Styles.ActionCardIconWrapper({
                                                fill: window.primaryColor
                                            })}>
                                                <DownloadMyDataIcon style={Styles.ActionIcons} />
                                            </Stack>
                                            <Text variant="large" block styles={Styles.ActionCardTitle}>
                                                Download My Data
                                            </Text>
                                            <Text variant="medium" block styles={Styles.ActionCardSubTitle}>
                                                Download all data relating to you held by our organization
                                            </Text>
                                            <DefaultButton
                                                styles={Styles.ActionCardButton}
                                                text="Proceed"
                                                onClick={() => this.redirectToDataSubjectRequest(REQUESTTYPES.DOWNLOAD)}
                                            />
                                        </div>
                                    </DocumentCard>
                                </Stack.Item>
                            )}
                            {actions["eraseDataEnable"] && (
                                <Stack.Item>
                                    <DocumentCard className={Styles.ActionCardWrapper({
                                        color: window.primaryColor
                                    })}>
                                        <div style={Styles.ActionCardDetailsWrapper}>
                                            <Stack className={Styles.ActionCardIconWrapper({
                                                fill: window.primaryColor
                                            })}>
                                                <EriseIcon style={Styles.ActionIcons}/>
                                            </Stack>
                                            <Text variant="large" block styles={Styles.ActionCardTitle}>
                                                Erase Me
                                            </Text>
                                            <Text variant="medium" block styles={Styles.ActionCardSubTitle}>
                                                Delete all data relating to you held by our organization
                                            </Text>
                                            <DefaultButton
                                                styles={Styles.ActionCardButton}
                                                text="Proceed"
                                                onClick={() => this.redirectToDataSubjectRequest(REQUESTTYPES.ERASE)}
                                            />
                                        </div>
                                    </DocumentCard>
                                </Stack.Item>
                            )}
                            {actions["consentEnable"] && (
                                <Stack.Item>
                                    <DocumentCard className={Styles.ActionCardWrapper({
                                        color: window.primaryColor
                                    })}>
                                        <div style={Styles.ActionCardDetailsWrapper}>
                                            <Stack className={Styles.ActionCardIconWrapper({
                                                fill: window.primaryColor
                                            })}>
                                                <ConsentIcon style={Styles.ActionIcons}/>
                                            </Stack>
                                            <Text variant="large" block styles={Styles.ActionCardTitle}>
                                                Consent
                                            </Text>
                                            <Text variant="medium" block styles={Styles.ActionCardSubTitle}>
                                                Manage your consent to our different data processing activities
                                            </Text>
                                            <DefaultButton
                                                styles={Styles.ActionCardButton}
                                                text="Proceed"
                                                onClick={() => this.redirectToDataSubjectRequest(REQUESTTYPES.CONSENT)}
                                            />
                                        </div>
                                    </DocumentCard>
                                </Stack.Item>
                            )}
                            {actions["notSellMyDataEnable"] && (
                                <Stack.Item>
                                    <DocumentCard className={Styles.ActionCardWrapper({
                                        color: window.primaryColor
                                    })}>
                                        <div style={Styles.ActionCardDetailsWrapper}>
                                            <Stack className={Styles.ActionCardIconWrapper({
                                                fill: window.primaryColor
                                            })}>
                                                <SellMyDataIcon style={Styles.ActionIcons}/>
                                            </Stack>
                                            <Text variant="large" block styles={Styles.ActionCardTitle}>
                                            Do Not Sell My Data
                                            </Text>
                                            <Text variant="medium" block styles={Styles.ActionCardSubTitle}>
                                            Do not sell my data to other organization
                                            </Text>
                                            <DefaultButton
                                                styles={Styles.ActionCardButton}
                                                text="Proceed"
                                                onClick={() => this.redirectToDataSubjectRequest(REQUESTTYPES.DONOTSELL)}
                                            />
                                        </div>
                                    </DocumentCard>
                                </Stack.Item>
                            )}

                            {actions["updateInaccuraciesEnable"] && (
                                <Stack.Item>
                                    <DocumentCard className={Styles.ActionCardWrapper({
                                        color: window.primaryColor
                                    })}>
                                        <div style={Styles.ActionCardDetailsWrapper}>
                                            <Stack className={Styles.ActionCardIconWrapper({
                                                fill: window.primaryColor
                                            })}>
                                                <UpdateIcon style={Styles.ActionIcons} />
                                            </Stack>
                                            <Text variant="large" block styles={Styles.ActionCardTitle}>
                                            Update Inaccuracies
                                            </Text>
                                            <Text variant="medium" block styles={Styles.ActionCardSubTitle}>
                                            Update an inaccuracy you've noticed in our records
                                            </Text>
                                            <DefaultButton
                                                styles={Styles.ActionCardButton}
                                                text="Proceed"
                                                onClick={() => this.redirectToDataSubjectRequest(REQUESTTYPES.INACCURACIES)}
                                            />
                                        </div>
                                    </DocumentCard>
                                </Stack.Item>
                            )}
                        </React.Fragment>
                    ) : (
                        <Stack.Item grow>
                            <Loader />
                        </Stack.Item>
                    )}
                </Stack>
            );
        } else if (t.name === TABNAMES.DATAPRACTICES) {
            const { dataPractice, isDataPracticeLoaded } = this.state;
            return (
                <React.Fragment>
                    {isDataPracticeLoaded ? (
                        <div
                            className="editorhtml"
                            dangerouslySetInnerHTML={{
                                __html: dataPractice,
                            }}
                        ></div>
                    ) : (
                        <Loader />
                    )}
                </React.Fragment>
            );
        } else if (t.name === TABNAMES.POLICY) {
            const { privacyContent, isPrivacyPolicyLoaded } = this.state;
            return (
                <React.Fragment>
                    {isPrivacyPolicyLoaded ? (
                        <div
                            style={CommonStyles.mt2}
                            className="editorhtml"
                            dangerouslySetInnerHTML={{
                                __html: privacyContent,
                            }}
                        ></div>
                    ) : (
                        <Loader />
                    )}
                </React.Fragment>
            );
        }
    };

    render() {
        const { isTabConfigLoaded, tabConfigData, successMessageMain } =
            this.state;
        return (
            <Stack>
                <Stack.Item>
                    {successMessageMain && (
                        <MessageBar messageBarType={MessageBarType.success}>
                            {successMessageMain}
                        </MessageBar>
                    )}
                    <Stack style={CommonStyles.alignCenter}>
                        <Text
                            variant="xxLarge"
                            block
                            styles={Styles.privacyHeadline}
                        >
                            {window.privacyCenterHeadline}
                        </Text>
                        <Text
                            variant="mediumPlus"
                            block
                            styles={Styles.privacySubtitle}
                        >
                            {window.privacyCenterSubtitle}
                        </Text>
                    </Stack>

                    <Stack vertical grow>
                        <Stack.Item>
                            {isTabConfigLoaded ? (
                                <Pivot
                                    id="privacycenter"
                                    onLinkClick={(event) =>
                                        this.handleSelect(event.props.eventKey)
                                    }
                                    styles={Styles.privacyCenterPivot}
                                    overflowBehavior={'menu'}
                                >
                                    {tabConfigData &&
                                        tabConfigData.map((t) => (
                                            <PivotItem
                                                key={t.id}
                                                eventKey={t.name}
                                                headerText={t.displayName}
                                            >
                                                {this.getTabData(t)}
                                            </PivotItem>
                                        ))}
                                </Pivot>
                            ) : (
                                <div className="col-md-12 text-center">
                                    <Loader />
                                </div>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    }
}
