import {mergeStyles} from "@fluentui/react";

export const Styles = {
    headerMenuIcon: { 
        root: { color: "#fff" },
        menuIcon: { display: "none" } 
    },
    privacyHeader: (custom) => {
    return {
        root: {
            height: "68px",
            textAlign: "center",
            display: 'block',
            ".ms-Stack-inner":{
                    background: custom.color,
                    top : 0,
                    height: "60px",
                    padding: '10px',
                    marginTop: "-16px",
                    position: "sticky",
                    alignItems: "center",
                    zIndex: 3,
            },
            "::before": {
                content: '""',
                display: "block",
                height: "16px",
                position: "sticky",
                top: "36px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.5)"
            },
            "::after": {
                content: '""',
                display: "block",
                height: "16px",
                position: "sticky",
                background: "linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 70%, transparent)",
                top: 0,
                zIndex: 2,
            },
        }
    }
    },
    headerText:{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
    },
    headerLogo: (custom) => {
        return mergeStyles({
            maxHeight: "60px",
            maxWidth: "180px",
            '@media(max-width: 425px)': {
                maxWidth: "90px"
            },
        })
    },
    headerTextInner: (custom) => {
        return mergeStyles({
            '@media(min-width: 500px)': {
                fontSize: "24px"
            },
            '@media(max-width: 500px)': {
                fontSize: "18px"
            },
            color: custom.color
        })
    },
    LoggedInUser:{
        marginLeft: "auto",
    },
    mainLogo: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    }
};
