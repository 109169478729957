import {mergeStyles, mergeStyleSets} from "@fluentui/react";

export const Styles = {
    preferenceIcons: {
        fontSize: "14px",
        lineHeight: "normal",
        verticalAlign: "middle",
        marginLeft: "8px",
    },
    preferenceHeaderText:{
      root: { marginBottom: "8px",fontSize:"24px" }
    },
    preferenceSubtext:{
      root: {
          '@media(min-width: 900px)': {
              width:"628px",
          },
          fontSize: "16px",
          lineHeight: "24px"
      }
    },
    preferenceToggle: mergeStyleSets({
        root: {
            marginTop: "20px",
            marginBottom: 0,
        },
        label: {
            marginBottom: "5px",
        }
    }),
    preferenceTooltip:{
        calloutProps: {
            styles: {
                root:{width: "194px"},
                beak: { background: '#191919',left:"-4px !important" },
                beakCurtain: { background: '#191919',borderRadius:"4px"},
                calloutMain: { background: '#191919', selectors:{".ms-Tooltip-content":{color:"#fff"}}},
            },
        },
    },
    privacyHeadline: mergeStyleSets({
        root: {
            '@media(max-width: 900px)': {
                padding: "0 6px",
                marginBottom: "24px",
            },
            '@media(min-width: 900px)': {
                marginBottom: "6px",
            },
            fontSize: "36px",
            lineHeight:"36px",
            fontWeight:"bold",
        }
    }),
    privacySubtitle: mergeStyleSets({
        root: {
            marginTop: "10px",
            marginBottom: "40px",
            lineHeight:"24px",
            '@media(min-width: 900px)': {
                padding: "0px 150px",
            },
            '@media(max-width: 900px)': {
                padding: "0px",
            },
        }

    }),
    privacyCenterPivot: mergeStyleSets({
        root: {
            marginBottom: "65px",
            '@media(max-width: 500px)': {
               marginLeft: "-10px"
            },
            selectors:{
                ".overflowMenuButton-102":{
                    background:"#fff",
                    ".flexContainer-102":{
                      fontSize:"16px",
                    },
                    ":hover :active": {
                        backgroundColor:"#ffffff",
                    }
                }
            }
        },
        link: {
            marginRight: "24px"
        }
    }),
    ActionIcons: {
        fontSize: "24px",
        lineHeight: "normal",
        color: "#fff",
    },
    ActionCard:{
        root: {
            selectors: {
                '.ms-Stack-inner': {
                    '@media(max-width: 900px)': {
                        justifyContent: "center"
                    }
                }
            }
        }
    },
    ActionCardWrapper: (customStyle)=> {
        return mergeStyles( {
            cursor: "pointer",

            '@media(min-width: 600px)': {
                maxWidth: "230px",
                minWidth: "230px",
                minHeight: "319px",
            },
            '@media(max-width: 600px)': {
                maxWidth: "282px",
                minWidth: "282px",
                minHeight: "292px",
            },
            "&:hover": {
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px",
                border: `1px solid ${customStyle.color}`,
            }
        })
    },
    ActionCardDetailsWrapper: {
        padding: "32px 16px",
        textAlign: "center",
    },
    ActionCardIconWrapper:(customStyle) => {
       return mergeStyles( {
               height: "60px",
               width: "50px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               margin: "auto",
               marginBottom: "20px",
               selectors: {
                   "svg > path:first-child": {
                       fill: customStyle.fill
                   }
               }
       })
    },
    ActionCardTitle: mergeStyleSets({
        root: {
            fontWeight: "bold"
        }
    }),
    ActionCardSubTitle: mergeStyleSets({
        root: {
            fontSize: "15px",
            marginBottom: "30px",
            marginTop: "20px",
            lineHeight:"24px"
        }
    }),
    ActionCardButton:  mergeStyleSets({
        root: {
            position: "absolute",
            bottom: "32px",
            width:"138px",
            height:"36px",
            border: "1px solid #DFDFDF",
            borderRadius:"2px",
            transform: "translateX(-50%)",
        }
    }),
    ActionCardImage: mergeStyleSets({
        root: {
            backgroundColor: "#fff"
        }
    }),
    ActionCardFooter: mergeStyleSets({
        root: {
            marginTop: "50px",
            padding: "10px",
            borderTop: "1px solid #dcdcdc",
        }
    }),
};
