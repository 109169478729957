import axios from "axios";
import { authenticationService } from "./authenticationservice";

const API = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: authenticationService.getHeader(),
  });

  instance.interceptors.request.use(function (config) {
    if (config.method.toUpperCase() === "GET") {
      config.params = {
        ...(config.params ? config.params : {}),
        domain: window.location.origin,
        domainName: window.location.origin,
      };
    } else {
      config.data = {
        ...(config.data ? config.data : {}),
        domainName: window.location.origin,
        domain: window.location.origin,
      };
    }
    return config;
  });

  return instance;
};

export default API;
