import React, { Component } from "react";
import Loader from "./loader";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { authenticationService } from "../services/authenticationservice";
import { verifyEmail as verifyPrivacyEmail } from "../services/privacy";
import { CONSENTTYPE } from "./constant";
import {
    Stack,
    Text,
    FontIcon,
    MessageBar,
    MessageBarType,
} from "@fluentui/react";
import { Styles as CommonStyles } from "../styles/common.styles";

export class VerifyRequest extends Component {
    static displayName = VerifyRequest.name;
    constructor(props) {
        super(props);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.state = {
            errorMessage: null,
            isLoaded: false,
            isVerify: false,
        };
    }

    componentDidMount() {
        this.verifyEmail();
    }

    async verifyEmail() {
        let params = queryString.parse(this.props.location.search);
        if (!(params && params.id)) {
            this.props.history.replace({
                pathname: "/",
            });
            return;
        }
        var id = params.id;
        const response = await verifyPrivacyEmail(id);
        if (response.success) {
            const requestData = response.data.result;
            if (requestData) {
                const user = {
                    email: requestData.email,
                    residentType: requestData.residentType,
                    subjectType: requestData.subjectType,
                };
                authenticationService.login(user);
            }
            if (!(response.data.isError)) {
                if (requestData && requestData.requestType === CONSENTTYPE) {
                    this.props.history.replace({
                        pathname: "/consentmanagement",
                        state: {
                            subjectEmail: requestData.email,
                            dataSubjectRequestId: requestData.id,
                        },
                    });
                } else {
                    this.setState({ isLoaded: true, isVerify: true });
                }
            } else {
                this.setState(
                    {
                        errorMessage: response.data.responseException["exceptionMessage"],
                    },
                    () =>
                        setTimeout(() => {
                            this.setState({ errorMessage: null });
                        }, 30000)
                );
            }
        }
        else {
            this.setState(
                {
                    errorMessage: response.data.responseException["exceptionMessage"],
                },
                () =>
                    setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 30000)
            );
        }
        this.setState({ isLoaded: true });
    }

    render() {
        const { isLoaded, isVerify, errorMessage } = this.state;
        return (
            <Stack>
                <Stack.Item>
                    <Link to="/" style={CommonStyles.iconLink}>
                        <div>
                            <FontIcon
                                iconName="Back"
                                aria-hidden={true}
                                style={CommonStyles.backIcon}
                            />
                        </div>
                        <span>Back to Privacy Center</span>
                    </Link>
                </Stack.Item>
                <Stack.Item style={CommonStyles.mt2}>
                    {errorMessage && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    {!isLoaded ? (
                        <Loader />
                    ) : (
                        isVerify && (
                            <div>
                                <div
                                    style={{
                                        color: window.secondaryColor,
                                        textAlign: "center",
                                    }}
                                >
                                    <FontIcon
                                        iconName="MailCheck"
                                        aria-hidden={true}
                                        style={{ fontSize: "100px" }}
                                    />
                                </div>
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <Text variant="medium">
                                        Thanks for verifying you email. We really value your privacy and security, You request is now being processed.
                                        You will get updated email about your request. Now your are logged in to our system. You can process your other request without verifying your email.
                                    </Text>
                                </div>
                            </div>
                        )
                    )}
                </Stack.Item>
            </Stack>
        );
    }
}
