import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as registerServiceWorker from './registerServiceWorker';
require('dotenv').config()
console.log(process.env.NODE_ENV, process.env.REACT_APP_API_BASE_URL);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);
registerServiceWorker.unregister();

