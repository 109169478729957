import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { Styles as CommonStyles } from "../styles/common.styles";

class Confirmation extends React.Component {
    render() {
        const {
            okLabel = 'Yes',
            cancelLabel = 'No',
            title ="Delete Confirm",
            confirmation,
            show,
            proceed,
            dismiss,
            cancel,
            showOk = true,
            dialogContentProps = {},
            modalProps = {},
        } = this.props;
        return (
            <Dialog
                hidden={!show}
                onDismiss={dismiss}
                dialogContentProps={{
                    title: title,
                    subText: confirmation,
                    ...dialogContentProps,
                }}
                modalProps={modalProps}
            >
                <DialogFooter>
                    {showOk && (
                        <PrimaryButton
                            onClick={proceed}
                            text={okLabel}
                            className={CommonStyles.primaryButton({ backgroundColor: window.primaryColor, color: window.secondaryColor })}
                        />
                    )}
                    <DefaultButton onClick={cancel} text={cancelLabel} />
                </DialogFooter>
            </Dialog>
        );
    }
}

Confirmation.propTypes = {
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func,     // called when ok button is clicked.
    cancel: PropTypes.func,      // called when cancel button is clicked.
    dismiss: PropTypes.func,     // called when backdrop is clicked or escaped.
    dialogContentProps: PropTypes.object,
    modalProps: PropTypes.object,
}

export default confirmable(Confirmation);